import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  getHHMM(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  getHHMMSS(date: Date): string {
    console.log(" time=", date);

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${second}`;
  }

  updateHHMM(date: Date, hours: number, minutes: number): Date {

    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  }

  convertDateAndTime(date: Date) {

    return this.convertDate(date) + " " + this.getTime(date);
  }

  convertDate(date: Date) {
    if (!date)
      return

    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

    return year + '-' + month + '-' + day;
  }

  getTime(date: Date) {

    return (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ":"
      + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ":"
      + (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  }

  findDifference(startDate, endDate) {
    return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24));
  }


  getTodayServerDate(): string {
    const today = new Date();
    return this.formatDate(today);
  }

  getYesterdayServerDate(): string {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return this.formatDate(yesterday);
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  stripTime(date: Date): Date {

    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  convertTo12HourFormat(time: string): string {

    if (time) {
      let [hour, minute] = time.split(':').map(Number);
      const period = hour >= 12 ? 'PM' : 'AM';
      hour = hour % 12 || 12;
      const hourStr = hour.toString().padStart(2, '0');
      const minuteStr = minute.toString().padStart(2, '0');
      return `${hourStr}:${minuteStr} ${period}`;
    }
    return time;
  }

  isDateExpired(date: Date): boolean {

    date.setHours(0, 0, 0, 0);
    const curerntDate = new Date();
    curerntDate.setHours(0, 0, 0, 0);
    return date < curerntDate;
  }

  isDateExpiredOrEqual(date: Date): boolean {

    date.setHours(0, 0, 0, 0);
    const curerntDate = new Date();
    curerntDate.setHours(0, 0, 0, 0);
    return date <= curerntDate;
  }

  isBetween6to11Am(start: number, end: number): boolean {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    return currentHour >= start && currentHour < end;
  }

}